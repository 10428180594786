import axios from 'axios';

export default class Element {
    constructor(config, ref) {
        this.config = config;
        this.ref = ref;
        /** @type HTMLElement */
        this.container = ref[0];

        if (!this.ref.attr('is-initialized')) {
            this.setInitialized(true);

            this.init();
            this.initListeners();
        }
    }

    setInitialized(value) {
        this.ref.attr('is-initialized', value ? 'true' : null);
    }

    init() {

    }

    initListeners() {

    }

    /**
     * @see \XLib\Http\XlibResponse::getRenderSingleElementConfig()
     * @param requestParams
     * @return {Promise<{name: string, data: Object<string,*>, rendered: string, render_errors: string}>}
     */
    renderSingleElement(requestParams)
    {
        const xhrConfig = this.config.xhrConfig || {};

        if (! xhrConfig?.url || ! xhrConfig?.method?.toLowerCase || ! xhrConfig?.params) {
            console.error('please provide a xhrConfig!');
            return new Promise((resolve) => resolve());
        }
        xhrConfig.method = xhrConfig.method.toLowerCase();

        const xlibElementClassPath = xhrConfig.params.only_dispatch[0];
        const queryParams = xhrConfig.method === 'get' ? {...xhrConfig.params, ...requestParams} : {...xhrConfig.params};
        const bodyParams = xhrConfig.method === 'post' ? {...requestParams} : {};

        return axios({
            method      : xhrConfig.method,
            url         : xhrConfig.url,
            params      : queryParams,
            data        : bodyParams,
            responseType: 'json',
            headers     : {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type'    : 'application/json',
                'Accept'          : 'application/json',
            },
        })
            .then((response) =>
                {
                    const elementResult = response.data.elements
                        .find(element => element.name === xlibElementClassPath);

                    if (elementResult.render_errors) {
                        console.error(`Render errors for ${elementResult.name}: ${elementResult.render_errors}`);
                    }
                    return elementResult;
                },
            );
    }
}
