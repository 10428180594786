import $ from 'cash-dom';
import {Element} from "plugins/xlib/js/xlib";

export default class ThemeSwitch extends Element {
    initListeners() {
        this.ref.on('click', () => {
            const theme = this.ref.data('theme');
            $.get(
                '/wp/wp-admin/admin-ajax.php',
                {
                    action: 'theme_switch',
                    theme: theme
                },
                () => {
                    location.reload();
                }
            );
        })
    }
}
