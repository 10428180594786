import 'jquery';
import 'magnific-popup';

$('[data-magnific]').bind('click.magnific', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    const url = $(this).data('magnific');

    $.magnificPopup.open({
        items: {
            src: url
        },
        type: 'image',
        closeOnContentClick: true,
        fixedContentPos: true,
        mainClass: 'mfp-with-zoom'
    });
})

// JavaScript Document
$("#AssetsFilter input[type='checkbox']").change(function () {
    $('#Position').val($(document).scrollTop());
    $("#AssetsFilter").submit();
});
$("#AssetsFilterReset input[type='checkbox']").change(function () {
    $('#PositionReset').val($(document).scrollTop());
    $("#AssetsFilterReset").submit();
});

function countUp()
{
    $('.counter').each(function () {
        var $this = $(this);
        jQuery({ Counter: 0 }).animate({ Counter: $this.attr('data-stop') }, {
            duration: 1700,
            easing: 'swing',
            step: function (now) {
                $this.text(Math.ceil(now));
            }
        });
    });
}

const countDIV = document.querySelectorAll(".teaser--counter");
const countDIVObserver = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            countUp();
        }
    },{});
});
countDIV.forEach(function (lazyBackground) {
    countDIVObserver.observe(lazyBackground);
});

const bannerDIV = document.querySelectorAll(".banner--full");
const bannerDIVObserver = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            $('.banner--full .banner__img').addClass('visible');
        }
    },{});
});
bannerDIV.forEach(function (BannerFull) {
    bannerDIVObserver.observe(BannerFull);
});

/* Gutenberg Styling */
$('.wp-block-table.is-style-checklist tbody tr td:first-child').on('click', function (e) {
    $(this).toggleClass('checked');
});

/* Mainnav */
/* Keep dropdown open when submenu is opend */
$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }
    var $subMenu = $(this).next('.dropdown-menu');
    $subMenu.toggleClass('show');
    $('.dropdown-menu a.dropdown-toggle').removeClass('active');
    $(this).addClass('active');
    return false;
});

/* Prevent dropdown to close by click inside */
$('.dropdown-menu').on('click', function (e) {
    event.stopPropagation();
});

/* Close dropdowns by click on button */
$('.menu__close').click(function () {
    $(this).parent().prev('.nav-link').dropdown('hide');
});

/* Close second level dropdowns by click on button */
$('.menu__back').click(function () {
    $(this).parent().removeClass('show');
    $(this).parent().prev('.dropdown-item').removeClass('active');
});

$('#mainMenu .nav-link.dropdown-toggle').click(function () {
    $('.navbar-collapse').addClass('open-first');
});
$('#mainMenu .nav-link.dropdown-toggle').click(function () {
    $('.navbar-collapse').addClass('open-first');
});
$('#mainMenu .dropdown-item.dropdown-toggle').click(function () {
    $('.navbar-collapse').addClass('open-second');
});
$('.menu__back--sm.back--first').click(function () {
    $(this).parent().removeClass('show');
    $('#mainMenu').removeClass('open-first');
});
$('.menu__back--sm.back--second').click(function () {
    $(this).parent().removeClass('show');
    $('#mainMenu').removeClass('open-second');
});

/* Show overlay */
function setNavs(x)
{
    if (x.matches) {
        $('#mainMenu .dropdown--main').on('show.bs.dropdown', function () {
            $('body').addClass('menu-lg-open');
        });

        $('#mainMenu .dropdown--main').on('hide.bs.dropdown', function () {
            $('body').removeClass('menu-lg-open');
            var $openSub = $('.dropdown-menu--sub.show');
            $openSub.removeClass('show');
            $openSub.prev('.dropdown-item').removeClass('active');
        });
    } else {
        /* Hook into bootstrap menu collapse */
        $('#mainMenu').on('show.bs.collapse', function () {
            $('.menu-icon').addClass('open');
            $('body').addClass('menu-sm-open');
            window.scrollTo(0, 0);
        });
        $('#mainMenu').on('hide.bs.collapse', function () {
            $('.menu-icon').removeClass('open');
            $('body').removeClass('menu-sm-open');
            $('#mainMenu').removeClass('open-first');
            $('#mainMenu').removeClass('open-second');
            $('.dropdown-menu.show').removeClass('show');
            $('.dropdown-menu--sub.show').removeClass('show');
        });
    }
}
var x = window.matchMedia("(min-width: 992px)");
setNavs(x);
x.addListener(setNavs);

/* enable tooltipps */
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

$('#TabAusgaben').click(function () {
    $('#results_editions').removeClass('hidden');
    $('#results_article').addClass('hidden');
});
$('#TabArtikel').click(function () {
    $('#results_editions').addClass('hidden');
    $('#results_article').removeClass('hidden');
});


const checkIfElementHasClass = (element, hasClass) => {
    const elements = document.querySelectorAll(element);
    return Array.from(elements).some(element => element.classList.contains(hasClass));
};

document.querySelectorAll('.anker__toggle').forEach(item => {
    item.addEventListener('click', function(e) {
        document.querySelectorAll('.anker__nav, .anker__toggle').forEach(navItem => {
            if (checkIfElementHasClass('.anker__nav', 'open')) {
                navItem.classList.remove('open');
            } else {
                navItem.classList.add('open');
            }
        });
    });
});

/* Article progress bar */
$(document).ready(function () {
    $('#check').on("click", function(){
        if (checkIfElementHasClass('body', 'menu-open')) {
            document.querySelector( "body" ).classList.remove('menu-open');
        } else {
            document.querySelector( "body" ).classList.add('menu-open');
        }
    });

    $( ".nav-toggle" ).on( "click", function() {
        $(this).closest("li").toggleClass('open');
    });

    var zoom = 0; // max = 60 min = -20
    function doZoom(value) {
        zoom = Math.min(60, Math.max(-20, value));

        // Down button
        if (zoom > -20) {
            $('#down').removeClass('inactive');
        } else if (zoom <= -20) {
            $('#down').addClass('inactive');
        }

        // Up button
        if (zoom < 60) {
            $('#up').removeClass('inactive');
        } else if (zoom >= 60) {
            $('#up').addClass('inactive');
        }

        var size = 100 + zoom;

        $('.article__editor p').css('font-size', size + '%');
        $('.article__editor h2').css('font-size', size + '%');
        $('.article__editor h3').css('font-size', size + '%');
        $('.article__editor h4').css('font-size', size + '%');
        $('.article__editor ul').css('font-size', size + '%');

        // Set cookie (expires in 30 days)
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        let expires = 'expires=' + d.toUTCString();
        document.cookie = 'zoomLevel=' + zoom + ';' + expires + ';path=/';
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    var cookieZoom = getCookie('zoomLevel');
    if (cookieZoom !== '') {
        doZoom(parseInt(cookieZoom));
    }

    $( "#up" ).on( "click", function() {
        doZoom(zoom + 10);
    });
    $( "#down" ).on( "click", function() {
        doZoom(zoom - 10);
    });
    $('#reset').on('click', function() {
        doZoom(0);
    });


    var catitem = $(".related__cats .cat__item").length;
    x=14;
    $('.related__cats .cat__item:lt('+x+')').addClass('show');
    $('#loadMore').click(function () {
        x= (x+14 <= catitem) ? x+14 : catitem;
        $('.related__cats .cat__item:lt('+x+')').addClass('show');
    });
    $('#showLess').click(function () {
        x=(x-14<0) ? 3 : x-14;
        $('.related__cats .cat__item').not(':lt('+x+')').removeClass('show');
    });


    /**
     * Sticky Header
     */
    $(window).scroll(function () {
        const elStickyHeader = $('.sticky-header');
        const elProductAnchor = $('.productAnchor');
        const elBody = $('body');

        const scroll = Math.floor($(window).scrollTop());

        if (scroll > 160) {
            elStickyHeader.addClass('sticked');
            elBody.addClass('nav--sticked');
            elProductAnchor.addClass('scrolled');
        } else if (scroll < 90) {
            elStickyHeader.removeClass('sticked');
            elBody.removeClass('nav--sticked');
            elProductAnchor.removeClass('scrolled');
        }
    })

    var getMax = function () {
        return $(document).height() - $(window).height();
    }

    var getValue = function () {
        return $(window).scrollTop();
    }

    if ('max' in document.createElement('progress')) {
        var progressBar = $('progress');

        progressBar.attr({
            max: getMax()
        });

        $(document).on('scroll', function () {
            progressBar.attr({
                value: getValue()
            });
        });

        $(window).resize(function () {

            progressBar.attr({
                max: getMax(),
                value: getValue()
            });
        });
    } else {
        var progressBar = $('.progress-bar'),
            max = getMax(),
            value, width;

        var getWidth = function () {

            value = getValue();
            width = (value / max) * 100;
            width = width + '%';
            return width;
        }

        var setWidth = function () {
            progressBar.css({
                width: getWidth()
            });
        }

        $(document).on('scroll', setWidth);
        $(window).on('resize', function () {

            max = getMax();
            setWidth();
        });
    }

    /* scroll-to-top button */
    var back_to_top_button = ['<a href="" class="back-to-top" title="An den Seitenanfang springen"><span class="more-arrow"></span></a>'].join("");
    $("body").append(back_to_top_button)
    $(".back-to-top").hide();

    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.back-to-top').show();
            } else {
                $('.back-to-top').hide();
            }
        });

        $('.back-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });
});

/* Breadcrumb */
(function ($, window, document, undefined) {
    // Create the defaults once
    var pluginName = 'breadcrumbs',
        pluginCssBlock = '.' + pluginName,
        cssExpanded = pluginName + '_expanded',
        cssToggle = pluginName + '__toggle',
        cssOverflow = pluginName + '_overflow',
        defaults = {
            /*propertyName: "value"*/
        };

    // The actual plugin constructor
    function Plugin(element, options)
    {
        this.element = element;
        this.$element = $(element);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Plugin.prototype = {

        init: function () {
            this.injectExpander(this.$element);
            this.checkOverflow();
            var self = this;

            var lazyResize = $.proxy(this.debounce(this.checkOverflow, 300), self);
            var lazyToggle = $.proxy(this.doToggle, self);
            this.$element.on('click.' + pluginName, '.' + cssToggle, lazyToggle);
            $(window).on('resize.' + pluginName, lazyResize);
        },

        isOverflowed: function (el) {
            // Determins if we have overflow
            return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
        },

        checkOverflow: function () {
            if (this.isOverflowed(this.element)) {
                this.$element.addClass(cssOverflow);
            } else {
                this.$element.removeClass(cssOverflow);
                // double check to make sure that that toggle is still valid
                if (this.isOverflowed(this.element)) {
                    this.$element.addClass(cssOverflow);
                }
            }
        },

        doCollapse: function () {
            this.$element
                .addClass(cssExpanded);
        },

        doExpand: function () {
            this.$element
                .removeClass(cssExpanded);
        },

        doToggle: function (evt) {
            evt.preventDefault();
            var $el = this.$element;

            if ($el.hasClass(cssExpanded)) {
                this.doExpand();
            } else {
                this.doCollapse();
            }

        },

        injectExpander: function ($el) {
            if (!$el.has(cssToggle).length) {
                $el
                    .children(':first')
                    .after($('<li class="' + cssToggle + '"><a href="#"></a></li>'));
            }
        },

        debounce: function (func, wait, immediate) {
            var timeout, args, context, timestamp, result;

            var now = Date.now || function () {
                return new Date().getTime();
            };

            var later = function () {
                var last = now() - timestamp;

                if (last < wait && last >= 0) {
                    timeout = setTimeout(later, wait - last);
                } else {
                    timeout = null;
                    if (!immediate) {
                        result = func.apply(context, args);
                        if (!timeout) {
                            context = args = null;
                        }
                    }
                }
            };

            return function () {
                context = this;
                args = arguments;
                timestamp = now();
                var callNow = immediate && !timeout;
                if (!timeout) {
                    timeout = setTimeout(later, wait);
                }
                if (callNow) {
                    result = func.apply(context, args);
                    context = args = null;
                }

                return result;
            };
        }
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(
                    this,
                    'plugin_' + pluginName,
                    new Plugin(this, options)
                );
            }
        });
    };

    // AutoInit based on predefined class (.breadcrumbs). Remove if you don't want this
    $(document).ready(function () {
        $(pluginCssBlock)[pluginName]();
    });

})(jQuery, window, document);


import 'owl.carousel';

$('.owl-carousel--banner').owlCarousel({
    loop: false,
    margin: 30,
    responsiveClass: true,
    responsive:{
        0: {
            items: 1,
            margin: 15,
            stagePadding: 30,
            nav: false,
            dotsEach: 2
        },
        576: {
            items: 2,
            nav: false
        },
        1024: {
            items: 3,
            nav: true,
            loop: false
        }
    }
});

$('.owl-carousel--experts').owlCarousel({
    loop: false,
    margin: 30,
    responsiveClass: true,
    responsive:{
        0:{
            items:1,
            margin: 15,
            stagePadding: 30,
            nav:false
        },
        576:{
            items:2,
            nav:false
        },
        1000:{
            items:4,
            nav:false,
            loop:false
        }
    }
});
$('.owl-carousel--expertshome').owlCarousel({
    loop: false,
    margin: 30,
    responsiveClass: true,
    responsive:{
        0:{
            items:2,
            margin: 15,
            stagePadding: 30,
            nav:false
        },
        576:{
            items:2,
            nav:false
        },
        1000:{
            items:5,
            nav:true,
            dots:false,
            loop:false
        }
    }
});

$('.owl-carousel--bonus').owlCarousel({
    loop:false,
    margin: 30,
    responsiveClass: true,
    responsive:{
        0:{
            items:1,
            margin: 15,
            stagePadding: 50,
            nav:false
        },
        576:{
            items:2,
            nav:false
        },
        1000:{
            items:2,
            nav:true,
            loop:false
        }
    }
});

$('.owl-carousel--testis').owlCarousel({
    loop:false,
    margin: 30,
    responsiveClass: true,
    responsive:{
        0:{
            items:1,
            margin: 15,
            stagePadding: 50,
            nav:false
        },
        576:{
            items:2,
            nav:false
        },
        1000:{
            items:3,
            nav:false,
            loop:false
        }
    }
});

/*****************
 * Expert Filter *
 *****************/
var expertCats = [];
$('.experts .filter__item').click(function () {
    var cat = $(this).data('cat');
    var filterExperts = function () {
        $('.experts_row .teaser-col').hide();
        expertCats.forEach(function (catID) {
            $('.experts_row .teaser-col').each(function () {
                var hasCats = $(this).data('cats');
                console.log(hasCats, catID);
                if (hasCats.includes(',' + catID + ' ') ) {
                    $(this).show();
                }
            });
        });
    }

    if ($(this).hasClass("filter__active")) {
        $(this).removeClass('filter__active');
        $(this).addClass('filter__inactive');

        var myIndex = expertCats.indexOf(cat);
        if (myIndex !== -1) {
            expertCats.splice(myIndex, 1);
        }

        if (expertCats.length) {
            filterExperts();
        } else {
            $('.experts_row .teaser-col').show();
        }
    } else {
        $(this).removeClass('filter__inactive');
        $(this).addClass('filter__active');

        expertCats.push(cat);

        filterExperts();
    }
});

/* SHOW BY BUTTON IN PRODUCT VIEW */
if ( $(".buy--sticky").length) {
    if (window.matchMedia('(min-width: 993px)').matches) {
        $(window).scroll(function () {
            if ($(this).scrollTop()>620 && $(this).scrollTop() < ($('#trustElement').offset().top - 600)) {
                $('.buy--sticky').slideDown(160);
            } else {
                $('.buy--sticky').slideUp(160);
            }
        });
    }
    if (window.matchMedia('(max-width: 992px)').matches) {
        $(window).scroll(function () {
            if ($(this).scrollTop()>820 && $(this).scrollTop() < ($('#trustElement').offset().top - 800)) {
                $('.buy--sticky').slideDown(160);
                $('.buy--sticky').css('display', 'flex');
                $('.contact-bot').css('bottom', '110px');
            } else {
                $('.buy--sticky').slideUp(160);
                $('.buy--sticky').css('display', 'none');
                $('.contact-bot').css('bottom', '16px');
            }
        });
    }
}

/* TOGGLE BOT */
$('#toggleBot').on('click', function (e) {
    $('.bot__window').addClass('show');
    $('.contact-bot').addClass('show');
});
$('#closeBot').on('click', function (e) {
    $('.bot__window').removeClass('show');
    $('.contact-bot').removeClass('show');
});

/* CONTACT FORM */
$("#ContactForm").submit(function ( event ) {
    var e_commit = '';
    var e_name = '';
    var e_mail = '';
    var e_nachricht = '';

    if ($("input[name=Commit]").is(':checked')) {
        $(".error_Commit").addClass("none");
        e_commit = '1';
    } else {
        $(".error_Commit").removeClass("none");
        e_commit = '0';
    }
    if ($("input[name=NameHoney]").val().length >= 3) {
        $(".error_NameHoney").addClass("none");
        $(".error_NameHoney").parent().closest('div').removeClass("error");
        e_name = '1';
    } else {
        $(".error_NameHoney").removeClass("none");
        $(".error_NameHoney").parent().closest('div').addClass("error");
        e_name = '0';
    }
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    var emailAdress = $("input[name=EmailHoney]").val();
    if (emailReg.test(emailAdress) && emailAdress != '') {
        $(".error_EmailHoney").addClass("none");
        $(".error_EmailHoney").parent().closest('div').removeClass("error");
        e_mail = '1';
    } else {
        $(".error_EmailHoney").removeClass("none");
        $(".error_EmailHoney").parent().closest('div').addClass("error");
        e_mail = '0';
    }
    if ($("textarea[name=Nachricht]").val().length >= 3 && $("textarea[name=Nachricht]").val() != 'Nachricht') {
        $(".error_Nachricht").addClass("none");
        $(".error_Nachricht").parent().closest('div').removeClass("error");
        e_nachricht = '1';
    } else {
        $(".error_Nachricht").removeClass("none");
        $(".error_Nachricht").parent().closest('div').addClass("error");
        e_nachricht = '0';
    }

    if (e_name === '1' && e_mail === '1' && e_nachricht === '1' && e_commit === '1') {
        return;
    }

    event.preventDefault();
});